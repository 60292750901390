import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../config/axios";

const initialState = {
   loading: false,
   userList: [],
   error: "",
};

export const fetchAllClientUser = createAsyncThunk(
   "clientUser/fetchAllClientUser",
   async () => {
      try {
         const response = await API.get("/clientuser/get-client-users");
         return response.data.data;
      } catch (err) {
         return err;
      }
   }
);

const clientUsersSlice = createSlice({
   name: "clientUser",
   initialState,
   reducers: {
      reset(state) {
         state.loading = false;
         state.error = "";
         state.userList = [];
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchAllClientUser.pending, (state) => {
            state.loading = true;
         })
         .addCase(fetchAllClientUser.fulfilled, (state, action) => {
            state.loading = false;
            state.userList = action.payload;
         })
         .addCase(fetchAllClientUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
         });
   },
});

export default clientUsersSlice.reducer;
export const { reset } = clientUsersSlice.actions;
