import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../config/axios";

export const getAllChats = createAsyncThunk(
   "chat/getAllChats",
   async ({ searchKey = "", page = 1 }) => {
      const params = {
         page,
      };

      // Only include the search parameter if searchKey is provided
      if (searchKey) {
         params.searchKey = searchKey;
      }
      try {
         const response = await API.get(`/chats`, { params });

         return response.data;
      } catch (err) {
         throw err;
      }
   }
);

export const assignChat = createAsyncThunk("chat/assignChat", async (body) => {
   try {
      const response = await API.post(`/chats/assign`, body);

      return response.data;
   } catch (err) {
      throw err;
   }
});

const initialState = {
   chats: [],
   groupChats: [],
   isGroupChat: false,
   searchResult: [],
   loading: false,
   error: null,
   page: 1,
   clicked: false,
   totalNotification: 0,
   newAddedChat: {},
   totalChatCount: 0,
};

const chatSlice = createSlice({
   name: "chat",
   initialState,
   reducers: {
      clearChat(state) {
         state.chats = [];
         state.searchResult = [];
         state.page = 1;
      },

      setGroupChat(state) {
         state.isGroupChat = !state.isGroupChat;
      },
      addNewAddedChat(state, action) {
         state.newAddedChat = action.payload;
      },
      setNewChatEmpty(state) {
         state.newAddedChat = {};
      },
      removeNewAddedChat(state) {
         state.newAddedChat = {};
      },
      updatePage(state) {
         state.page = state.page + 1;
      },
      updateNewChat(state, action) {
         state.chats = [...state.chats, action.payload];
      },
      setSearchedChat(state, action) {
         // console.log(action.payload)
         //  const searchTerm = action.payload.toLowerCase(); // Convert search term to lowercase

         //  state.searchResult = state.chats.filter((chatObject) => {
         //     const hasNameMatch = chatObject.chat.name
         //        ?.toLowerCase()
         //        .includes(searchTerm);
         //     const hasPhoneNumberMatch =
         //        chatObject.chat.phone_number?.includes(searchTerm);

         //     // Return the chat if either name or phone number matches
         //     return hasNameMatch || hasPhoneNumberMatch;
         //  });

         state.searchResult = action.payload;
      },
      updateTotalNotification(state, action) {
         let total = 0;
         state.chats?.forEach((item) => {
            if (item.messageCount > 0) {
               total = total + 1;
            }
         });
         state.totalNotification = total;
      },
      updateNewMessageCount(state, action) {
         const { chatId, count } = action.payload;
         const index = state.chats?.findIndex(
            (contact) => contact.chat._id === chatId
         );
         if (index === -1) return;
         // Create a new array and replace the old state
         state.chats = state.chats.map((chat, i) =>
            i === index ? { ...chat, messageCount: count } : chat
         );
         state.searchResult = state.chats.map((chat, i) =>
            i === index ? { ...chat, messageCount: count } : chat
         );
         // let updatedChats = [...state.chats];

         // updatedChats[index] = { ...updatedChats[index], messageCount: count };
         // console.log(updatedChats)
         // state.chats = updatedChats;
         // const updatedChat = state.chats?.map((chatData) => {
         //    const { _id } = chatData.chat;
         //    if (_id === chatId) {
         //       chatData.messageCount = count;
         //    }
         //    return chatData;
         // });
         // state.chats = updatedChat;
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(getAllChats.pending, (state) => {
            state.loading = true;
         })
         .addCase(getAllChats.fulfilled, (state, action) => {
            const { result, page, totalChat, notificationCount } =
               action.payload;
            state.loading = false;
            if (Number(page) === 1) {
               state.chats = result;
               state.searchResult = result;
            } else {
               state.chats = [...state.chats, ...result];
               state.searchResult = [...state.searchResult, ...result];
            }
            // let total = 0;
            // result?.forEach((item) => {
            //    if (item.messageCount > 0) {
            //       total = total + 1;
            //    }
            // });
            state.totalNotification = notificationCount;
            state.page = page;
            state.totalChatCount = totalChat;
         })
         .addCase(getAllChats.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
         });
   },

   // reducers: {
   //   saveSelectedChat(state, action) {
   //     return {
   //       ...state,
   //       selectedChat: action.payload,
   //     };
   //   },
   // },
});

export default chatSlice.reducer;

export const {
   updateNewChat,
   updateNewMessageCount,
   updateTotalNotification,
   addNewAddedChat,
   setNewChatEmpty,
   removeNewAddedChat,
   setSearchedChat,
   setGroupChat,
   updatePage,
   clearChat,
} = chatSlice.actions;
